<template>
    <v-container fluid>
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
            <form @submit.prevent="handleSubmit(updateSite())">
                <v-row>
                    <v-col class="text-start">
                        <v-tooltip right>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn v-bind="attrs" v-on="on" icon @click="backToSites">
                                    <v-icon>mdi-arrow-left</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{ $vuetify.lang.t('$vuetify.pages.site.backToSites') }}
                            </span>
                        </v-tooltip>
                    </v-col>
                    <v-col class="text-end" cols="8" xl="6">
                        <v-btn :disabled="fetchingSiteData" :loading="fetchingSiteData" text
                               @click="fetchSiteData">
                            {{ $vuetify.lang.t('$vuetify.general.reset') }}
                        </v-btn>
                        <v-btn :disabled="invalid" :loading="submittingSiteData" class="mx-5" text type="submit">
                            {{ $vuetify.lang.t('$vuetify.pages.site.saveAndContinue') }}
                        </v-btn>
                        <v-btn :disabled="invalid" :loading="submittingSiteData" color="primary" type="submit"
                               @click="submitAndClose = true">
                            {{ $vuetify.lang.t('$vuetify.pages.site.saveChanges') }}
                        </v-btn>
                    </v-col>
                    <v-col class="text-end" cols="auto">
                        <kurcc-user-options/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row align="center">
                                            <v-col cols="12">
                                                <v-card-title class="red--text text--darken-4">
                                                    {{ $vuetify.lang.t('$vuetify.pages.site.generalInfo') }}
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Site Name"
                                                                     rules="required|min:2|max:50">
                                                    <v-text-field v-model="siteData.name" :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.siteName')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Email"
                                                                     rules="required|email">
                                                    <v-text-field v-model="siteData.email" :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.email')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Company Name (EN)"
                                                                     rules="required|min:2|max:50">
                                                    <v-text-field v-model="siteData.company_name_en"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.companyNameEn')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Company Name (AR)"
                                                                     rules="required|min:2|max:50">
                                                    <v-text-field v-model="siteData.company_name_ar"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.companyNameAr')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-autocomplete
                                                    :items="categories"
                                                    :loading="fetchingCategories || fetchingSiteData"
                                                    :multiple="false"
                                                    :name="$vuetify.lang.t('$vuetify.pages.site.category')"
                                                    :outlined="true"
                                                    :selected-items.sync="siteData.category">
                                                </kurcc-autocomplete>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-image-input :image.sync="siteData.logo"
                                                                   :label="$vuetify.lang.t('$vuetify.pages.site.logo')"
                                                                   :loading="fetchingSiteData"
                                                                   :name="$vuetify.lang.t('$vuetify.pages.site.logo')"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="text-start">
                                                <span>
                                                {{
                                                        $vuetify.lang.t('$vuetify.pages.site.disabledMessage')
                                                    }} {{
                                                        siteData.enabled ? $vuetify.lang.t('$vuetify.general.enabled') : $vuetify.lang.t('$vuetify.general.disabled')
                                                    }}.
                                                </span>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <v-switch
                                                            v-model="siteData.enabled"
                                                            :label="siteData.enabled ? $vuetify.lang.t('$vuetify.general.enabled') : $vuetify.lang.t('$vuetify.general.disabled')"
                                                            class="ma-auto" hide-details/>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-image-preview v-if="siteData.logo" :src="siteData.logo"
                                                                     alt="site logo"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-btn :loading="fetchingSiteData" color="indigo" dark
                                                               large min-width="250" @click="$router.push({name: 'site-branches',
                                                                params: {site_id: siteData.id.toString()}})">
                                                            <v-icon left>mdi-source-branch</v-icon>
                                                            {{ $vuetify.lang.t('$vuetify.pages.site.viewBranches') }}
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-btn :loading="fetchingSiteData" color="indigo" dark
                                                               large min-width="250" @click="$router.push({name: 'site-inspections',
                                                                params: {site_id: siteData.id.toString()}})">
                                                            <v-icon left>mdi-text-box-check-outline</v-icon>
                                                            {{ $vuetify.lang.t('$vuetify.pages.site.viewInspections') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title class="red--text text--darken-4">
                                                    {{ $vuetify.lang.t('$vuetify.pages.site.commercialLicense') }}
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="License Number"
                                                                     rules="required">
                                                    <v-text-field v-model="siteData.commercial_license_number"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.licenseNumber')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-image-input :image.sync="siteData.commercial_license_photo"
                                                                   :label="$vuetify.lang.t('$vuetify.pages.site.licensePhoto')"
                                                                   :loading="fetchingSiteData"
                                                                   :name="`${$vuetify.lang.t('$vuetify.pages.site.commercialLicense')} ${$vuetify.lang.t('$vuetify.pages.site.licensePhoto')}`"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-date-picker :date.sync="siteData.commercial_license_expire_date"
                                                                   :label="$vuetify.lang.t('$vuetify.pages.site.licenseExpireDate')"
                                                                   name="Commercial License Expire Date"/>
                                            </v-col>
                                            <v-col v-if="siteData.commercial_license_photo" cols="6">
                                                <kurcc-image-preview :src="siteData.commercial_license_photo"
                                                                     alt="commercial license"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title class="red--text text--darken-4">
                                                    {{ $vuetify.lang.t('$vuetify.pages.site.seniorStaff') }}
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Position"
                                                                     rules="required|min:2|max:30">
                                                    <v-text-field v-model="siteData.senior_staff_position"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.position')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Staff Name"
                                                                     rules="required|min:2|max:30">
                                                    <v-text-field v-model="siteData.senior_staff_name"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.name')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Staff Email"
                                                                     rules="required|email">
                                                    <v-text-field v-model="siteData.senior_staff_email"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.email')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Staff Phone Number"
                                                                     rules="required">
                                                    <v-text-field v-model="siteData.senior_staff_phone_number"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.phoneNumber')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title class="red--text text--darken-4">
                                                    {{ $vuetify.lang.t('$vuetify.pages.site.paci') }}
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="PACI Number"
                                                                     rules="required">
                                                    <v-text-field v-model="siteData.paci_license_number"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.paciNumber')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-image-input :image.sync="siteData.paci_license_photo"
                                                                   :label="$vuetify.lang.t('$vuetify.pages.site.licensePhoto')"
                                                                   :loading="fetchingSiteData"
                                                                   :name="`${$vuetify.lang.t('$vuetify.pages.site.paci')} ${$vuetify.lang.t('$vuetify.pages.site.licensePhoto')}`"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-date-picker :date.sync="siteData.paci_license_expire_date"
                                                                   :label="$vuetify.lang.t('$vuetify.pages.site.licenseExpireDate')"
                                                                   name="PACI License Expire Date"/>
                                            </v-col>
                                            <v-col v-if="siteData.paci_license_photo" cols="6">
                                                <kurcc-image-preview :src="siteData.paci_license_photo"
                                                                     alt="paci license"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title class="red--text text--darken-4">
                                                    {{ $vuetify.lang.t('$vuetify.pages.site.municipality') }}
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-date-picker
                                                    :date.sync="siteData.municipality_license_expire_date"
                                                    :label="$vuetify.lang.t('$vuetify.pages.site.licenseExpireDate')"
                                                    name="Municipality License Expire Date"/>
                                            </v-col>
                                            <v-col cols="6">
                                                <kurcc-image-input :image.sync="siteData.municipality_license_photo"
                                                                   :label="$vuetify.lang.t('$vuetify.pages.site.licensePhoto')"
                                                                   :loading="fetchingSiteData"
                                                                   :name="`${$vuetify.lang.t('$vuetify.pages.site.municipality')} ${$vuetify.lang.t('$vuetify.pages.site.licensePhoto')}`"/>
                                            </v-col>
                                            <v-col v-if="siteData.municipality_license_photo" cols="6" offset="6">
                                                <kurcc-image-preview :src="siteData.municipality_license_photo"
                                                                     alt="municipality license"/>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card-title class="red--text text--darken-4">
                                                    {{ $vuetify.lang.t('$vuetify.pages.site.managingPartner') }}
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Name"
                                                                     rules="required|min:2|max:30">
                                                    <v-text-field v-model="siteData.managing_partner_name"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.name')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Partner Email"
                                                                     rules="required|email">
                                                    <v-text-field v-model="siteData.managing_partner_email"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.email')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                            <v-col cols="6">
                                                <validation-provider v-slot="{ errors }" name="Phone Number"
                                                                     rules="required">
                                                    <v-text-field v-model="siteData.managing_partner_phone_number"
                                                                  :error-messages="errors"
                                                                  :label="$vuetify.lang.t('$vuetify.pages.site.phoneNumber')"
                                                                  :loading="fetchingSiteData"
                                                                  outlined/>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </form>
            <v-dialog v-model="showSaveOrDiscardDialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title class="text-start">
                        Site data has been changed. Discard or save your changes?
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn text @click="discardSiteAndGoBackToSites">
                            Discard
                        </v-btn>
                        <v-btn class="mx-5" text @click.stop="showSaveOrDiscardDialog = false">
                            Continue to edit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </validation-observer>
    </v-container>
</template>

<script>
import {ValidationObserver} from 'vee-validate'
import Site from "@/modules/sites/models/site";

export default {
    name: "KurccSitePage",
    components: {
        KurccUserOptions: () => import('@/modules/app/components/KurccUserOptions'),
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete'),
        KurccDatePicker: () => import('@/modules/app/components/KurccDatePicker'),
        KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
        KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview'),
        ValidationObserver,
    },
    props: ['site', 'id'],
    data() {
        return {
            siteData: new Site(),
            initialSiteState: null,
            categories: [],
            submittingSiteData: false,
            fetchingCategories: false,
            fetchingSiteData: false,
            submitAndClose: false,
            showSaveOrDiscardDialog: false
        }
    },
    methods: {
        submit() {
            this.$refs.observer.validate().then(success => {
                if (success)
                    this.updateSite()
            })
        },
        fetchSiteData() {
            this.fetchingSiteData = true
            this.$store.dispatch('getSite', {id: this.id})
                .then(res => {
                    this.siteData = res
                    this.initialSiteState = res
                }).finally(() => {
                this.fetchingSiteData = false
            })
        },
        getAllCategories() {
            this.fetchingCategories = true
            this.$store.dispatch('getAllCategories', {withIncludes: false})
                .then(res => {
                    this.categories = res.items
                }).finally(() => {
                    this.fetchingCategories = false
                }
            )
        },
        updateSite() {
            this.submittingSiteData = true
            this.$store.dispatch('updateSite', {...this.siteData})
                .then(() => {
                    if (this.submitAndClose)
                        this.$router.push({name: 'sites'})
                    else
                        this.fetchSiteData()
                })
                .finally(() => {
                    this.submittingSiteData = false
                })
        },
        backToSites() {
            if (this.$lodash.isEqual(this.initialSiteState, this.siteData))
                this.$router.push({name: 'sites'})
            else {
                this.showSaveOrDiscardDialog = true
            }
        },
        discardSiteAndGoBackToSites() {
            this.siteData = this.$lodash.cloneDeep(this.initialSiteState)
            this.backToSites()
        }
    },
    created() {
        if (this.site) {
            this.siteData = this.$lodash.cloneDeep(this.site)
            this.initialSiteState = this.$lodash.cloneDeep(this.site)
        } else this.fetchSiteData()

        this.getAllCategories()
    }
}
</script>

<style lang="scss" scoped>
.w-100 {
    width: 100%;
}
</style>
